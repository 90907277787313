
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/user";
@Options({
  name: "login",
})
export default class extends Vue {
  private loading = false;
  private openid = "";
  private userData = {
    username: "",
    password: "",
    type: "project",
  };
  private isReadme = false;
  private isShow = false;
  private redirect = "";
  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    this.redirect = route.query && route.query.redirect;
    this.isReadme = this.$store.state.user.userInfo.isReadme;
  }

  mounted() {
    this.userData.username = localStorage.getItem("username") || "";
    this.userData.password = localStorage.getItem("password") || "";
    this.isReadme = Boolean(parseInt(localStorage.getItem("isReadme") || ""));
    this.weixinLogin();
  }

  weixinLogin() {
    // 获取URL中的参数code(微信)
    const weixinCode = this.getQueryVariable("code");
    if (weixinCode == null || weixinCode === "") {
      this.$toast.fail("获取微信授权失败");
    } else {
      // 将code发送给后台
      api.weixinLogin({ code: weixinCode }).then((res: any) => {
        this.openid = "";
        if (res.data !== undefined && res.data.openid !== undefined) {
          this.openid = res.data.openid;
        } else {
          this.loginSuccess(res.data);
        }
      });
    }
  }
  loginSuccess(data: any) {
    this.$store.commit("user/SET_TOKEN", data.token);
    this.$store.commit("user/SET_USERINFO", {
      id: "",
      username: this.userData.username,
      password: this.userData.password,
      type: data.type,
      currentEnter: data.currentEnter,
    });
    this.$store.dispatch("project/actionProjectList");
    this.$store.commit("user/SET_README", this.isReadme);
    this.$router.push({ path: "/" });
  }

  getQueryVariable(variable: any) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  private fClickShow() {
    this.isShow = !this.isShow;
  }

  private fSubmit() {
    (this as any).$refs.loginform.submit();
  }
  onSubmit() {
    // 如果登陆成功
    this.loading = true;
    const that = this as any;
    api.login(this.userData).then((res: any) => {
      if (res.code !== 0) {
        (this as any).$toast.fail(res.msg);
        this.loading = false;
      } else {
        this.$store.commit("user/SET_TOKEN", res.data.token);
        this.$store.commit("user/SET_USERINFO", {
          id: "",
          username: res.data.username,
          password: this.userData.password,
          type: res.data.type,
          currentEnter: res.data.currentEnter,
        });
        window.localStorage.setItem("username", this.userData.username);
        window.localStorage.setItem("password", this.userData.password);
        window.localStorage.setItem("isReadme", this.isReadme ? "1" : "0");
        this.$store.dispatch("project/actionProjectList");
        this.$store.commit("user/SET_README", this.isReadme);
        this.redirect = this.redirect ? this.redirect : "/";
        this.loading = false;
        (this as any).$toast.success({
          forbidClick: true,
          message: "登录成功!",
          onClose: () => {
            // 登录成功，获取微信openid（将该用户和微信绑定）
            if (this.openid !== "") {
              api.getOpenId(that.openid).then((res: any) => {
                if (res.code !== 0) {
                  this.$toast.fail("获取微信信息失败");
                }
              });
            }
            this.$router.push(this.redirect);
          },
        });
      }
    });
  }
}
